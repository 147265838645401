import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Cta } from '@components/Cta/Cta.jsx';
import { Title } from "@mantine/core";

export const renderOptions = {
    renderNode: {
        [INLINES.EMBEDDED_ENTRY]: (node, children) => {
            if (node.data.target.sys.contentType.sys.id === "cta") {
                return (
                    <Cta url={node.data.target.fields.url} title={node.data.target.fields.buttonText} variant={node.data.target.fields.variant} size={node.data.target.fields.size} icon={node.data.target.fields.icon} alignment={node.data.target.fields.iconAlignment} target={node.data.target.fields.newTab} />
                );
            }
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
            if (node.data.target.sys.contentType.sys.id === "video") {
                let host;
                if(node.data.target.fields.host === "Youtube") {
                    host = "https://www.youtube.com/embed/";
                } else if(node.data.target.fields.host === "Vimeo") {
                    host = "https://player.vimeo.com/video/";
                }
                return (
                    <div className="video">
                        <div className="video-player">
                            <iframe
                                src={`${host}${node.data.target.fields.videoId}`}
                                height="100%"
                                width="100%"
                                frameBorder="0"
                                scrolling="no"
                                title={node.data.target.fields.title}
                                allowFullScreen={true}/>
                        </div>
                        {node.data.target.fields.description && <p className="caption">{node.data.target.fields.description}</p>}
                    </div>
                );
            }
            if (node.data.target.sys.contentType.sys.id === "callOutBox") {
                return (
                    <div className="calloutbox">
                        <Title order={3}>{node.data.target.fields.title}</Title>
                        <div className="content-area">
                            {documentToReactComponents(node.data.target.fields.content, renderOptions)} 
                        </div>
                    </div>
                );
            }
            if (node.data.target.sys.contentType.sys.id === "testimonial") {
                return (
                    <blockquote className="testimonial">
                        <p className="quote-text">{node.data.target.fields.quoteText}</p>
                        <p className="quote-author">&mdash; {node.data.target.fields.authorAndRole}</p>
                    </blockquote>
                );
            }
        },
    
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
            return (
                <img
                    src={`https:${node.data.target.fields.file.url}?w=720`}
                    height={node.data.target.fields.file.details.image.height}
                    width={node.data.target.fields.file.details.image.width}
                    alt={node.data.target.fields.title ? node.data.target.fields.title : ""}
                />
            );
        },
    },
};
