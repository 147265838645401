"use client";

import { useDisclosure } from "@mantine/hooks";
import { Modal, Container, Grid, GridCol, Title, Text } from "@mantine/core";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { renderOptions } from "@lib/renderOptions";
import { Icon } from "@components/Icon/Icon.jsx";
import classes from './People.module.scss';

export function Bio({ name, photo, role, organisation, bio, link, linkLabel, icon }) {
    const [opened, { open, close }] = useDisclosure(false);
    return (
        <>
            <Modal opened={opened} onClose={close} size="55rem" zIndex={1100} yOffset="10dvh" overlayProps={{ color: '#291C33', backgroundOpacity: "0.8"}}>
                <Container size="lg">
                    <Grid>
                        <GridCol span={{ lg: 5 }}>
                            <img src={`https:${photo.fields.file.url}?fit=thumb&f=face&h=640&w=720&fm=avif`} width={360} height={320} alt={name} className={classes.bioPic} />
                        </GridCol>

                        <GridCol span={{ lg: 7 }}>
                            {organisation && <Text size="sm" mb="md">{organisation}</Text>}
                            <Title order={3} mb="sm">{name}</Title>
                            {role && <Title order={4} size="md" mb="md">{role}</Title>}
                            {bio &&
                            <div className="content-area">
                                {bio && documentToReactComponents(bio, renderOptions)}
                            </div>
                            }
                            {link && <p><a href={link} class={classes.socialLink} target="_blank" rel="noopener noreferrer"><Icon name={icon} /> {linkLabel}</a></p>}
                        </GridCol>
                    </Grid>
                </Container>
            </Modal>

            <button aria-label="Open details" className="btn-arrow" onClick={open}><Icon name="arrow-right" /></button>
        </>
    )
}